/* eslint-disable prefer-const */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import colors from '../../../assets/themes/base/colors';
import { ALLOWANCES, DEFAULT_SYMBOL_FILE_ICONS } from '../../../entities/Piping/FittingCategories/model';
import { Dropdown } from 'primereact/dropdown';
import Icon from '../../../components/common/Icon';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleFittingCategoriesPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';
import { convertToFittingCategoriesType, saveCurrentFittingCategoryChanges } from './actions';

// validation
import { rowStyles } from '../../../components/primeGrid/RowStyles';

// actions
import { processFetchAllFittingCategories, processSaveFittingCategories } from '../../../entities/Piping/FittingCategories/actions';

// selectors
import { selectPipingModulePermissionsAndState, selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { selectCurrentFilteredNormalizedFittingCategories, selectUnfilteredFittingCategoriesCount } from '../../../entities/Piping/FittingCategories/selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectCurrentFilteredNormalizedFittingCategories(),
  selectUnfilteredFittingCategoriesCount(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    data,
    totalSizes,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length == 0));
    return {
      ...rest,
      isFiltering,
      isLoading,
      canCollaborate,
      hasValidLicense,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      totalSizes,
    };
  },
);

function FittingCategoriesGrid() {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // editing state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToFittingCategoriesType, reduxProps.editable, true);

  //Cell displays
  const categoryBody = (item) => {
    return item && (
      <div style={{ maxWidth: '150px', overflow: 'ellipsis', height: '100%' }} >
        <Flex flexDirection='column' justifyContent='center' style={{ float: 'left', height: '100%' }} ><Icon style='ptc' name={item} iconStyle={{ height: '30px', width: '30px' }} /></Flex>
        <Flex flexDirection='column' justifyContent='center' style={{ height: '100%' }} ><Text style={{ float: 'right', textAlign: 'left' }} >{item}</Text></Flex>
      </div>
    );
  };

  const valueBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || { id: rowdata.id, symbolFile: rowdata.symbolFile, categoryName: rowdata.categoryName };
    if (curRow.id && curRow.id.includes('NEW_ROW_') && curRow[field] == '') {
      return (
        <Text style={{ color: colors.gray[5] }} >new row</Text>
      );
    }
    let body;
    switch(field) {
    case 'categoryName':
      body = <Text>{curRow && curRow[`${field}`]}</Text>;
      break;
    case 'symbolFile':
      body = categoryBody(curRow.symbolFile);
      break;
    }

    return body;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  // Cell Editors
  const textCellEditor = (field) => <InputText value={currentEditingRow && currentEditingRow[field] || ''} onChange={(e) => onCellChange(e.target.value, field)} keyfilter={ALLOWANCES.CATEGORY_NAME} />;
  const symbolFileDropDown = () =>
    <Dropdown
      value={currentEditingRow.symbolFile}
      onChange={(e) => {
        onDropdownComplete.current(e.value, 'symbolFile', currentEditingRow);
      }}
      options={DEFAULT_SYMBOL_FILE_ICONS}
      itemTemplate={(item) => categoryBody(item)}
      valueTemplate={(item) => categoryBody(item)}
      style={{ width: '100%', height: '90%' }} />;

  useEffect(() => {
    dispatch(processFetchAllFittingCategories());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={PipingModuleFittingCategoriesPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.totalSizes} ${pluralize('filtered Fitting Category', reduxProps.data.length - 10)}` :
            `Showing ${reduxProps.data.length - 15} ${pluralize('Fitting Category', reduxProps.data.length - 15)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentFittingCategoryChanges(
            editedRows,
            (data) => { dispatch(processSaveFittingCategories(data)); resetEditedRows(); },
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column
            field='categoryName'
            header='Category Name'
            style={{ width: '40%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('categoryName')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => valueBody(rowdata, 'categoryName')}
            sortable
          ></Column>
          <Column
            field='symbolFile'
            header='Symbol File'
            style={{ width: '40%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => symbolFileDropDown()}
            body={(rowdata) => valueBody(rowdata, 'symbolFile')}
            sortable
          ></Column>
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(FittingCategoriesGrid);
