import { useState, useRef, useEffect } from 'react';

export const useOnCellEditComplete = (data, convertToType, editable, useDropdowns=false) => {
  const [ editedRows, setEditedRows ] = useState([]);
  const [ currentEditingRow, setCurrentEditingRow ] = useState();
  const [ isEditing, setIsEditing ] = useState(false);

  // entity row start tools
  const removeItemFromRows = (originalRow) => {
    let _rows = [...editedRows];
    const rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
    _rows.splice(rowsItemIndex, 1);
    setEditedRows(_rows);
  };

  const handleRowAction = (item, iconName) => {
    const originalItem = data.find((itm) => item.id == itm.id);
    let _rows = [...editedRows];
    switch (iconName) {
    case 'undo':
      removeItemFromRows(originalItem);
      break;
    case 'delete':
      removeItemFromRows(originalItem);
      _rows.push({ ...(convertToType(originalItem)), isDeleted: true, isEdited: true });
      setEditedRows(_rows);
      break;
    }
  };

  // Cell Editing Management
  const onBeforeCellEditShow = (e) => {
    const curRow = editedRows && editedRows.find((item) => item.id == e.rowData.id) || convertToType(e.rowData);
    setCurrentEditingRow(curRow);
    setIsEditing(true);
  };

  const onCellChange = (value, field, customOnChange=false) => {
    // eslint-disable-next-line prefer-const
    let _editedRow = { ...currentEditingRow };
    if(customOnChange) {
      _editedRow = customOnChange(value);
    } else {
      _editedRow[field] = value;
    }
    setCurrentEditingRow(_editedRow);
  };

  const onCellEditCompleteRef = useRef(null);

  /**
   * manages the state of 'editedRows' to track changes to cells, optimized for easy use with PrimeReact datagrid's 'onCellEditComplete' callback
   * @param {import('primereact/column').ColumnEvent} e I think this is the right data type?
   * @returns null, edits state to reflect changes to the cell
   */
  const onCellEditComplete = (e) => {
    setIsEditing(false);
    const originalRow = data.find((item) => item.id == e.rowData.id);
    let _editedRow = convertToType(currentEditingRow);

    if (!editable) {
      // user does not have permission to edit ( 'user' level permissions or invalid license )
      return;
    }

    if (_editedRow.isDeleted || _editedRow.id != originalRow.id) {
      return;
    }

    let _rows = [...editedRows];

    // if the item is the same as the original item, remove it from the rows
    if (JSON.stringify(convertToType(_editedRow)) ===
      JSON.stringify(convertToType(originalRow))) {
      const rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
      if (rowsItemIndex != -1) {
        _rows.splice(rowsItemIndex, 1);
        setEditedRows(_rows);
        return;
      }
      return;
    }

    // if it is a new item, add it to editedRows
    if (originalRow.id.includes('NEW_ROW')) {
      const editedRowIndex = _rows.findIndex(item => item.id == originalRow.id);
      if (editedRowIndex != -1) { // row has already been created/edited
        _rows[editedRowIndex] = _editedRow;
      } else { // row needs to be added to the 'created rows' list
        _editedRow.isEdited = true;
        _editedRow.isCreated = true;
        _rows.push(_editedRow);
      }
      setEditedRows(_rows);
      return;
    }

    // if it is an existing item, add it to editedRows
    const currentRowItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
    if (currentRowItemIndex != -1) {
      //if the item is already in editedRows, edit it
      _rows[currentRowItemIndex] = _editedRow;
    } else {
      //if it is not, then add it to them
      _editedRow.isEdited = true;
      _rows.push(_editedRow);
    }
    setEditedRows(_rows);
  };

  const onDropdownCompleteRef = useRef(null);

  /**
   * not just for dropdowns, different form of this.onCellEditComplete optimized for use on cells without access to the PrimeReact.DataGrid.onCellEditComplete
   * must be used through the onDropdownCompleteRef, as state is consumed and may not be accurate without ref usage
   * @param {any} newValue the individual value to replace the original with
   * @param {string} field the field in the object to replace the value of
   * @param {any} originalEditedRow the original row that needs to be changed
   * @returns null, but edits state to reflect necessary changes
   */
  const onDropdownComplete = (newValue, field, originalEditedRow ) => {
    setIsEditing(false);
    const originalRow = data.find((item) => item.id == originalEditedRow.id);
    let _editedRow = convertToType(originalEditedRow);
    _editedRow[field] = newValue;

    if (!editable || _editedRow.isDeleted || _editedRow.id != originalRow.id ) {
      // user does not have permission to edit ( 'user' level permissions or invalid license )
      return;
    }
    let _rows = [...editedRows];

    // if the item is the same as the original item, remove it from the rows
    if (JSON.stringify(convertToType(_editedRow)) ===
      JSON.stringify(convertToType(originalRow))) {
      const rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
      if (rowsItemIndex != -1) {
        _rows.splice(rowsItemIndex, 1);
        setEditedRows(_rows);
        return;
      }
      return;
    }

    // if it is a new item, add it to editedRows
    if (originalRow.id.includes('NEW_ROW')) {
      let editedRow = _rows.find(item => item.id == originalRow.id);
      if (editedRow) { // row has already been created/edited
        editedRow[field] = _editedRow[field];
      } else { // row needs to be added to the 'created rows' list
        _editedRow.isEdited = true;
        _editedRow.isCreated = true;
        _rows.push(_editedRow);
      }
      setEditedRows(_rows);
      return;
    }

    // if it is an existing item, add it to editedRows
    let currentRowItem = _rows && _rows.find(item => item.id == originalRow.id);
    if (currentRowItem) {
      //if the item is already in editedRows, edit it
      currentRowItem[field] = _editedRow[field];
    } else {
      //if it is not, then add it to them
      _editedRow.isEdited = true;
      _rows.push(_editedRow);
    }
    setEditedRows(_rows);
  };

  useEffect(() => {
    //ref to solve value persistence (updates the function to use current values on state change)
    onCellEditCompleteRef.current = onCellEditComplete;
    if (useDropdowns) {
      onDropdownCompleteRef.current = onDropdownComplete;
    }
  }, [currentEditingRow, data]);

  if (useDropdowns) {
    return [editedRows, () => setEditedRows([]), currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditCompleteRef, handleRowAction, onDropdownCompleteRef];
  }

  return [editedRows, () => setEditedRows([]), currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditCompleteRef, handleRowAction];
};