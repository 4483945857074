import React from 'react';

// themes
import colors from '../../../assets/themes/base/colors';

// components
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import Text from '../../../components/common/Text';
import Button from '../../../components/common/Button';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import Icon from '../../../components/common/Icon';
import Flex from '../../../components/common/Flex';

export const convertToFittingFamilyType = ({
  id,
  name,
  selectionName,
  material,
  fittingCode,
  fittingCategory,
  boltNutFamily,
}) => ({
  id,
  name,
  selectionName,
  material,
  fittingCode,
  fittingCategory,
  boltNutFamily,
});

// field validation
// VALIDATION_FIELDS:
//   name
//   selectionName
//   material
//   fittingCode
//   fittingCategory

export function areFieldsValid({ name, selectionName, fittingCategory, fittingCode }) {
  // takes the currentEditedRow, which is similar to the FittingFamily type
  const _VALIDATION_FIELDS = {
    name: !!name,
    selectionName: !!selectionName,
    fittingCategory: !!fittingCategory,
    fittingCode: !!fittingCode,
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist ({name, selectionName, fittingCategory, fittingCode}) {
  // takws the VALIDATION_FIELDS type, so all values are booleans
  return !!name && !!selectionName && !!fittingCategory && !!fittingCode;
}

// cell bodies
export const nameBody = (rowdata, onClickAction) => {
  const transformedData = {
    primary: rowdata.name,
    tertiary: rowdata.instancesCount,
    to: true
  };

  return <div onClick={() => onClickAction(rowdata.id)} style={{cursor: 'pointer'}} ><ComplexCellRenderer value={transformedData} placeholder='' isCapitalized={true} paddingLeft='0px' /></div>;
};

export const materialBody = (rowdata) => {
  if (rowdata.material) {
    return (
      <Text>{rowdata.material.code}</Text>
    );
  }

  return (
    <Text style={{ color: colors.gray[5] }} >No Material</Text>
  );
};

export const codeBody = (rowdata) => {
  if (rowdata.fittingCode) {
    return <div style={{ maxWidth: '300px', overflow: 'ellipsis' }} >
      <Text>{rowdata.fittingCode.name}</Text>
    </div>;
  }
};

export const categoryBody = (rowdata) => {
  if (rowdata.fittingCategory) {
    return (
      <div style={{ maxWidth: '300px', overflow: 'ellipsis', height: '100%' }} >
        {rowdata.fittingCategory && <Flex flexDirection='column' justifyContent='center' style={{ float: 'left', height: '100%' }} ><Icon style='ptc' name={rowdata.fittingCategory.symbolFile} iconStyle={{ height: '30px', width: '30px' }} /></Flex>}
        <ComplexCellRenderer
          value={{ primary: rowdata.fittingCategory.categoryName, secondary: rowdata.fittingCategory.symbolFile }}
          placeholder={''}
          isCapitalized={false}
          paddingLeft='10px'
          style={{ float: 'right'}}
        />
      </div>
    );
  }
};

export const rowEndTools = (rowData, editAction, copyAction, listAction, archiveAction, unarchiveAction) => {

  return (
    <RowEndEntityTools
      rowdata={rowData}
      editAction={editAction}
      copyAction={copyAction}
      listAction={(data) => listAction(data.id)}
      archiveAction={archiveAction}
      unarchiveAction={unarchiveAction}
    />
  );
};

// Misc. Dialog Components
export const dialogFooter = (isCreateDialog, isCopyDialog, deleteAction, saveAction, cancelAction) => (
  <>
    {!isCreateDialog && !isCopyDialog && <Button error={true} onClick={deleteAction} style={{ float: 'left' }} >Delete</Button>}
    <Button secondary={true} onClick={cancelAction} >Cancel</Button>
    {!isCopyDialog ?
      <Button primary={true} onClick={saveAction} >Save</Button> :
      <Button primary={true} onClick={saveAction} >Copy</Button> }
  </>
);

export const deleteDialogFooter = (cancelAction, deleteAction) => (
  <>
    <Button secondary={true} onClick={cancelAction} >Cancel</Button>
    <Button error={true} onClick={deleteAction} >Delete</Button>
  </>
);
